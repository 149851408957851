<template>
  <!--  ant-design组件设置中文  -->
  <a-config-provider :locale="zhCN">
    <a-layout class="home">
      <!-- 左侧 -->
      <a-layout-sider
        :style="{ height: '100vh', background: '#fff' }"
        v-model:collapsed="collapsed"
        :trigger="null"
        collapsible
      >
        <!-- logo -->
        <a-row type="flex" align="middle" justify="center" class="logo">
          <a-col>
            <!-- <img v-if="!collapsed" :src="require(`@/assets/images/logo.png`)" />
            <img
              v-if="collapsed"
              :src="require(`@/assets/images/logo-mini.png`)"
            /> -->
          </a-col>
        </a-row>

        <!-- 导航菜单 -->
        <a-menu
          mode="inline"
          class="menus"
          :selectedKeys="[route.meta.id]"
        >
          <template v-for="item in menus" :key="item.id">
            <template v-if="item.status === 0">
              <template v-if="item.children.length == 0">
                <a-menu-item :key="item.id" @click="jumpUrl(item)">
                  <template #icon>
                    <icon-font :type="item.icon" />
                  </template>
                  <!-- <router-link :to="item.url"> -->
                  {{ item.name }}
                  <!-- </router-link> -->
                </a-menu-item>
              </template>
              <template v-else>
                <sub-menu
                  :menu-info="item"
                  :key="item.id"
                  @changeValue="showcontentChange"
                />
              </template>
            </template>
          </template>
        </a-menu>
      </a-layout-sider>

      <!-- 右侧 -->
      <a-layout class="right-layout">
        <a-layout-content class="right">
          <!-- header -->
          <a-row
            class="header"
            type="flex"
            justify="space-between"
            align="middle"
          >
            <a-col flex="10px">
              <icon-font
                type="icon-copy-4-22"
                class="trigger"
                v-if="collapsed"
                @click="collapsed = !collapsed"
              />
              <icon-font
                type="icon-copy-4-22"
                class="trigger"
                v-else
                @click="collapsed = !collapsed"
              />
            </a-col>

            <a-col flex="auto">
              <a-breadcrumb>
                <a-breadcrumb-item
                  v-for="(breadcrumb, i) in breadcrumbs"
                  :key="i"
                >
                  <span
                    v-if="
                      breadcrumbs.indexOf(breadcrumb) ===
                        breadcrumbs.length - 1 ||
                      breadcrumb.url == '' ||
                      breadcrumb.url === null
                    "
                  >
                    <icon-font :type="breadcrumb.icon" />
                    {{ breadcrumb.name }}
                  </span>
                  <router-link v-else :to="`${breadcrumb.route}`">
                    <icon-font :type="breadcrumb.icon" />
                    {{ breadcrumb.name }}
                  </router-link>
                </a-breadcrumb-item>
              </a-breadcrumb>
            </a-col>

         
          </a-row>
          <!-- 内容 -->
          <div class="content" v-if="showcontent == 1">
            <router-view />
          </div>
          <div class="contentOther" v-if="showcontent == 0">
            <router-view />
          </div>
        </a-layout-content>
      </a-layout>

      <a-drawer
        placement="right"
        :closable="false"
        v-model:visible="drawer"
        :bodyStyle="{ padding: 0 }"
        :width="400"
        :maskStyle="{ opacity: 0, animation: 'none' }"
      >
        <div class="the-drawer">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" tab="消息">
              <div class="flex-flex-end">
                <div class="operation-top-item">
                  <div
                    @click="allRead"
                    class="allRead-box bgcolor"
                    v-if="newMessage == true"
                  >
                    全部已读
                  </div>
                  <div class="allRead-box" v-else>全部已读</div>
                  <!-- <el-tooltip content="全部已读" placement="top" effect="light">
                      <icon-font type="icon-quanbuyidu" class="icon-operation" @click="allRead"/>
                    </el-tooltip> -->
                </div>
              </div>
              <div class="message-list" v-infinite-scroll="load">
                <div
                  class="message-box"
                  v-for="(items, s) in messagsList"
                  :key="s"
                >
                  <div class="message-time">
                    <div>{{ items.createTime }}</div>
                    <div>
                      <icon-font
                        type="icon-weidudianjihou"
                        class="icon-unread-color"
                        v-if="items.isRead == 0"
                        @click="setMessageRead(items.id)"
                      />
                      <icon-font
                        type="icon-u2076"
                        v-else
                        class="icon-read-color"
                      />
                    </div>
                  </div>
                  <div class="message-text" @click="setMessageRead(items.id)">
                    <div class="message-title">{{ items.title }}</div>
                    <div>
                      <span class="message-in">{{
                        items.content == null
                          ? ""
                          : JSON.parse(items.content).text
                      }}</span>
                      <a
                        v-if="items.content != null"
                        class="message-a"
                        :href="JSON.parse(items.content).downloadUrl"
                        >{{
                          JSON.parse(items.content).downloadUrl == null
                            ? ""
                            : "点击下载"
                        }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="通知">
              <div class="flex-flex-end">
                <div class="operation-top-item">
                  <div
                    @click="allRead"
                    class="allRead-box bgcolor"
                    v-if="newMessage == true"
                  >
                    全部已读
                  </div>
                  <div class="allRead-box" v-else>全部已读</div>
                </div>
              </div>
              <div class="message-list" v-infinite-scroll="load">
                <div
                  class="message-box"
                  v-for="(items, s) in messagsList"
                  :key="s"
                >
                  <div class="message-time">
                    <div>{{ items.createTime }}</div>
                    <div>
                      <icon-font
                        type="icon-weidudianjihou"
                        class="icon-unread-color"
                        v-if="items.isRead == 0"
                        @click="setMessageRead(items.id)"
                      />
                      <icon-font
                        type="icon-u2076"
                        v-else
                        class="icon-read-color"
                      />
                    </div>
                  </div>
                  <div class="message-text" @click="setMessageRead(items.id)">
                    <div class="message-title">{{ items.title }}</div>
                    <div>
                      <span class="message-in">{{
                        items.content == null
                          ? ""
                          : JSON.parse(items.content).text
                      }}</span>
                      <a
                        v-if="items.content != null"
                        class="message-a"
                        :href="JSON.parse(items.content).downloadUrl"
                        >{{
                          JSON.parse(items.content).downloadUrl == null
                            ? ""
                            : "点击下载"
                        }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <!-- <a-tab-pane key="3" tab="Tab 3">Content of Tab Pane 3</a-tab-pane> -->
          </a-tabs>
        </div>
        <!-- <div>
          <el-empty description="暂无数据"></el-empty>
        </div> -->
      </a-drawer>
      <!-- 弹窗 -->
      <div>
        <el-dialog
          title="修改密码"
          v-model="forgetPasswordDialogVisible"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width="30%"
        >
          <div class="p-0-30">
            <div class="flex-nowrap-flex-end">
              <span class="forget-password" @click="forgrtType = !forgrtType">
                {{ forgrtType == false ? "通过验证码修改" : "通过密码修改" }}
              </span>
            </div>
            <div class="p-10-0" v-if="forgrtType == false">
              <el-form
                label-position="left"
                size="small"
                label-width="100px"
                :model="forgrtForm"
                :rules="forgrtRules"
                ref="ForgrtForm"
              >
                <el-form-item label="旧密码：" prop="oldPassword">
                  <el-input
                    v-model="forgrtForm.oldPassword"
                    placeholder="请输入旧密码"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item label="新密码：" prop="newPassword">
                  <el-input
                    v-model="forgrtForm.newPassword"
                    placeholder="请输入新密码"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item label="确认密码：" prop="confirmPasswordOther">
                  <el-input
                    v-model="forgrtForm.confirmPasswordOther"
                    placeholder="请再次确认密码"
                    show-password
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="p-10-0" v-else>
              <el-form
                label-position="left"
                size="small"
                label-width="100px"
                :model="forgrtForm"
                :rules="forgrtRules"
                ref="ForgrtForm"
              >
                <el-form-item label="手机号：" prop="phone">
                  <el-input
                    v-model="forgrtForm.phone"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="验证码："
                  prop="code"
                  placeholder="请输入验证码"
                >
                  <el-input v-model="forgrtForm.code">
                    <template #append>
                      <el-button>
                        <span
                          class="span-width"
                          @click="getAuthCode"
                          v-show="sendAuthCode"
                          >获取验证码</span
                        >
                        <span
                          class="span-width"
                          @click="getAuthCode"
                          v-show="!sendAuthCode"
                          >{{ auth_time }}秒后重发</span
                        >
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="新密码：" prop="password">
                  <el-input
                    v-model="forgrtForm.password"
                    placeholder="请输入新密码"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item label="确认密码：" prop="confirmPassword">
                  <el-input
                    v-model="forgrtForm.confirmPassword"
                    placeholder="请再次确认密码"
                    show-password
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="flex-nowrap-flex-end">
              <el-button
                size="small"
                @click="forgetPasswordDialogVisible = false"
                >取消</el-button
              >
              <el-button size="small" class="button-confirm" @click="changeNow"
                >立即修改</el-button
              >
            </div>
          </div>
        </el-dialog>
      </div>
      <div class="popup-view">
        <el-form
          label-position="left"
          size="small"
          label-width="100px"
          :model="upForm"
          :rules="rules"
          ref="upform"
        >
          <el-dialog
            title="修改密码"
            v-model="centerDialogVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width="30%"
          >
            <div class="center-body p-0-20">
              <el-row :gutter="30">
                <el-col :span="24">
                  <el-form-item label="旧密码：" prop="oldPassword">
                    <el-input
                      v-model="upForm.oldPassword"
                      show-password
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="24">
                  <el-form-item label="新密码：" prop="newPassword">
                    <el-input
                      v-model="upForm.newPassword"
                      show-password
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <template #footer>
              <el-form-item>
                <el-button class="cancel" plain @click="resetForm()" round
                  >取消</el-button
                >
                <el-button class="confirmAdd" @click="submitForm" round
                  >修改</el-button
                >
              </el-form-item>
            </template>
          </el-dialog>
        </el-form>
      </div>
      <div class="popup-view">
        <el-form
          label-position="left"
          size="small"
          label-width="100px"
          :model="informationList"
          :rules="rules"
          ref="informationList"
        >
          <el-dialog
            title="修改信息"
            v-model="centerDialogVisible1"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width="30%"
          >
            <div style="display: flex">
              <div>
                <span style="color: #8b96cc">头像：</span>
                <imgUp1
                  :width="90"
                  :height="90"
                  :type="'1'"
                  ops="0"
                  index="0"
                  :img="informationList.avatar"
                  @success="handleImgSuccess"
                  @delete="handleImgDelete"
                ></imgUp1>
              </div>
              <div class="center-body p-0-20">
                <el-row :gutter="30">
                  <el-col :span="24">
                    <el-form-item label="昵称：" prop="nickname">
                      <el-input v-model="informationList.nickname"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="30">
                  <el-col :span="24">
                    <el-form-item label="用户名：" prop="name">
                      <el-input v-model="informationList.name"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="30">
                  <el-col :span="24">
                    <el-form-item label="手机号：" prop="phone">
                      <el-input v-model="informationList.phone"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>

            <template #footer>
              <el-form-item>
                <el-button
                  class="cancel"
                  plain
                  @click="centerDialogVisible1 = false"
                  round
                  >取消</el-button
                >
                <el-button
                  class="confirmAdd"
                  @click="submitForm1('informationList')"
                  round
                  >修改</el-button
                >
              </el-form-item>
            </template>
          </el-dialog>
        </el-form>
      </div>
      <!-- 弹窗 -->
      <div class="popup-view height-set-one">
        <el-dialog
          title="设置密码"
          v-model="setPasswordDialogVisible"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          :show-close="false"
          width="30%"
        >
          <template #title>
            <div class="flex-nowrap-flex-start">
              <div class="icon-font-out-box flex-nowrap-center-center">
                <icon-font type="icon-xinxitianxie" class="icon-first-font" />
              </div>
              <div class="icon-font-out-title ml-20">
                恭喜注册成功，请进行基础信息的完善~
              </div>
            </div>
          </template>
          <div class="perfectForm-box">
            <el-form
              label-position="left"
              label-width="130px"
              :model="perfectForm"
              :rules="rules"
              ref="perfectForms"
            >
              <el-form-item label="企业名称：" prop="company">
                <div class="line-el-select">
                  <el-input
                    v-model="perfectForm.company"
                    size="small"
                    placeholder="请输入企业名称"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="昵称：" prop="nickname">
                <div class="line-el-select">
                  <el-input
                    v-model="perfectForm.nickname"
                    size="small"
                    placeholder="请设置昵称"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="当前账户：">
                <div class="line-el-select">
                  {{ perfectForm.phone }}
                </div>
              </el-form-item>
              <el-form-item label="设置密码：" prop="endPassword">
                <div class="line-el-select">
                  <el-input
                    v-model="perfectForm.endPassword"
                    size="small"
                    placeholder="请输入密码"
                    show-password
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="再次输入密码：" prop="beginPassword">
                <div class="line-el-select">
                  <el-input
                    v-model="perfectForm.beginPassword"
                    size="small"
                    placeholder="请确认密码"
                    show-password
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="flex-nowrap-flex-end">
                  <el-button
                    class="cancel"
                    plain
                    @click="resetPasswordForm()"
                    round
                    >取消</el-button
                  >
                  <el-button
                    class="button-confirm"
                    size="medium"
                    @click="saveInformation"
                    round
                    >保存</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
      </div>
      <!-- 弹窗购买模块 -->
      <div>
        <el-form
          label-position="top"
          label-width="100px"
          :model="setPasswordFrom"
          :rules="rules"
          ref="setPasswordfrom"
        >
          <el-dialog title="开通模块" v-model="openDialogVisible" width="30%">
            <div class="center-body">
              <open :appId="openAppId" @fatherMethod="closeDiv" />
            </div>
            <!-- <template #footer>
                  <el-form-item>
                      <el-button class="cancel" plain @click="resetPasswordForm()" round>取消</el-button>
                      <el-button class="confirmAdd" @click="submitPasswordForm" round>设置</el-button>
                  </el-form-item>
              </template> -->
          </el-dialog>
        </el-form>
      </div>
    </a-layout>
  </a-config-provider>
</template>

<script>
import topUp from "@/components/topUp";
import imgUp1 from "@/components/imgUpLoad";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  watch,
  getCurrentInstance,
  UnwrapRef,
  toRaw,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { IconFont } from "@/utils/iconfont";
import { removeToken, getToken } from "@/plugins/cookie";
import SubMenu from "@/components/SubMenu";
import user from "@/api/system/user";
import users from "@/api/web/user";
import auth from "@/api/web/auth";
import common from "@/api/web/common";
import webUser from "@/api/web/user";
import systemAuth from "@/api/system/auth";
import message from "@/api/system/message";
import webMessage from "@/api/web/message";
import { getParentByPath, getParents } from "@/public/js/tool";
import { ElNotification } from "element-plus";
import { websocket } from "@/utils/websocket";
import open from "@/views/OpenModule";
import emitter from "@/utils/bus.js";
import { ElMessage } from "element-plus";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default defineComponent({
  components: {
    IconFont,
    SubMenu,
    open,
    // eslint-disable-next-line vue/no-unused-components
    topUp,
    imgUp1,
  },
  data() {
    return {
      informationList: {
        nickname: "",
        phone: "",
        name: "",
        avatar: "",
      },
    };
  },
  methods: {
    showChage() {
      this.centerDialogVisible1 = true;
      this.informationList.avatar = this.$store.getters.user.avatar;
      this.informationList.name = this.$store.getters.user.username;
      this.informationList.nickname = this.$store.getters.user.nickname;
      this.informationList.phone = this.$store.getters.user.phone;
    },
    handleImgSuccess(val) {
      this.informationList.avatar = val;
    },
    handleImgDelete() {
      this.informationList.avatar = null;
    },
    submitForm1(informationList) {
      this.$refs[informationList].validate((valid) => {
        if (valid) {
          webUser
            .editInfo({
              uid: this.$store.getters.user.uid,
              avatar: this.informationList.avatar
                ? this.informationList.avatar
                : "",
              phone: this.informationList.phone,
              nickname: this.informationList.nickname,
              username: this.informationList.name,
            })
            .then((res) => {
              if (res.code == 200) {
                ElNotification({
                  title: "成功",
                  message: "修改成功,请等待页面刷新！",
                  type: "success",
                });
                this.centerDialogVisible1 = false;
                window.location.reload();
              } else {
                ElNotification({
                  title: "失败",
                  message: res.message || res.msg,
                  type: "error",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  setup() {
    const balance = ref(0);
    const routes = ref([]);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const userType = store.getters.userType;

    getData();
    var validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== forgrtForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePasswordOther = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== forgrtForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    // let userType = ref<string>('')

    // 获取用户菜单
    let menus = ref([]);
    let allMenus = ref([]);
    let breadcrumbs = ref([]);

    const addMenuItem = () => {
      // 创建一个新的菜单项对象
      const newMenuItem = {
        id: "newMenuItem",
        name: "New Menu Item",
        icon: "newIcon",
        status: 0,
        children: [], // 可选，根据您的需求添加子菜单
      };

      // 将新菜单项添加到menus数组中
      menus.value.push(newMenuItem);
    };
    // const getMenu = () => {
    //   if (store.getters.userType == 'system') {
    //     systemUser.getUserMenuTree().then((res) => menuhandler(res))
    //   }
    //   if (store.getters.userType == 'web') {
    //     webUser.getUserMenuTree().then((res) => menuhandler(res))
    //   }
    // }
    // const menuhandler = (res) => {
    //   allMenus.value = store.getters.menus[0].filter(x => x.status == 0) //[res.data[0]].filter(x => x.status == 0)
    //   menus.value = store.getters.menus[0].children.filter(x => x.status == 0)
    //   breadcrumbs.value = getParents(allMenus.value, (x) => x.id == route.meta.id)?.reverse()
    // }

    // 获取用户类型
    // async function getUserType () {
    //   await auth.getUserType().then((res) => {
    //     userType.value = res.data
    //     store.dispatch('setUserType', res.data)
    //   })
    // }

    // 获取用户信息
    // const getUserInfo = () => {
    //   if (store.getters.userType == 'system') {
    //     systemUser.getInfo().then((res) => {
    //       store.dispatch('setUser', res.data)
    //     })
    //   }
    //   if (store.getters.userType == 'web') {
    //     webUser.getInfo().then((res) => {
    //       store.dispatch('setUser', res.data)
    //     })
    //   }
    // }
    // ============================ 二级路由跳转 =================================
    let openDialogVisible = ref(false);
    let showcontent = ref(0);
    let openAppId = ref("");

    function jumpUrl(item) {
        router.push({
          path: item.url,
        });
        return
      const currentDomain = window.location;
      console.log(
        "000000000000000000000000000000000000000000000000000000000000000000",
        item,
        currentDomain
      );
      if (item.appId != 0 && item.children.length == 0) {
        openAppId.value = item.appId;
        openDialogVisible.value = true;
      } else {
        if (item.route == "/Dashboard") {
          showcontent.value = 0;
        }
        router.push({
          path: item.url,
        });
      }
    }
    function getData() {
      if (userType == "web") {
        users.getInfo({}).then((res) => {
          balance.value = res.data.balance;
        });
      }
    }
    function closeDiv() {
      openDialogVisible.value = false;
      router.go(0);
    }

    function showcontentChange() {
      showcontent.value = 1;
    }

    // ============================ 用户信息操作中心 =================================
    // 个人中心
    function goPersonal() {
      showcontent.value = 1;
      router.push("/web/personal");
    }

    // 完善个人信息
    let perfectForm = reactive({
      beginPassword: "",
      company: "",
      endPassword: "",
      nickname: "",
      phone: "",
    });
    let perfectForms = ref();

    function saveInformation() {
      perfectForms.value.validate((valid) => {
        if (valid) {
          webUser
            .perfection({
              ...perfectForm,
            })
            .then((res) => {
              ElNotification({
                title: "成功",
                message: "初始信息设置成功！",
                type: "success",
              });
              setPasswordDialogVisible.value = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }

    // 修改密码
    let centerDialogVisible = ref(false);
    let centerDialogVisible1 = ref(false);
    let upForm = reactive({
      oldPassword: "",
      newPassword: "",
    });
    let rules = reactive({
      nickname: [
        { required: true, message: "请设置昵称", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "用户昵称长度为2-30个字符",
          trigger: "blur",
        },
      ],
      name: [
        { required: true, message: "请设置用户名", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "用户名长度为2-30个字符",
          trigger: "blur",
        },
      ],
      phone: [
        { required: true, message: "请设置手机号", trigger: "blur" },
        {
          min: 11,
          max: 11,
          message: "手机号码为11位字符",
          trigger: "blur",
        },
      ],
      company: [
        { required: true, message: "请输入企业名称", trigger: "blur" },
        {
          min: 2,
          max: 30,
          message: "企业名称长度为2-30个字符",
          trigger: "blur",
        },
      ],
      oldPassword: [
        { required: true, message: "请输入用户旧密码", trigger: "blur" },
        {
          min: 6,
          max: 15,
          message: "用户密码长度为6-15个字符",
          trigger: "blur",
        },
      ],

      newPassword: [
        { required: true, message: "请输入用户新密码", trigger: "blur" },
        {
          min: 6,
          max: 15,
          message: "用户密码长度为6-15个字符",
          trigger: "blur",
        },
      ],
      password: [
        { required: true, message: "请输入用户密码", trigger: "blur" },
        {
          min: 6,
          max: 15,
          message: "用户密码长度为6-15个字符",
          trigger: "blur",
        },
      ],
      endPassword: [
        { required: true, message: "请输入用户密码", trigger: "blur" },
        {
          min: 6,
          max: 15,
          message: "用户密码长度为6-15个字符",
          trigger: "blur",
        },
      ],
      beginPassword: [
        { required: true, message: "请再次输入用户密码", trigger: "blur" },
        {
          min: 6,
          max: 15,
          message: "用户密码长度为6-15个字符",
          trigger: "blur",
        },
      ],
    });
    // 用户修改密码
    // 忘记密码
    let forgrtRules = reactive({
      phone: [
        { required: true, message: "请输入手机号", trigger: "change" },
        {
          pattern:
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: "请输入正确的手机号",
          trigger: "change",
        },
      ],
      code: [
        { required: true, message: "请输入验证码", trigger: "change" },
        { pattern: /^\d{6}$/, message: "验证码格式不正确", trigger: "change" },
      ],
      oldPassword: [
        { required: true, message: "请输入用户旧密码", trigger: "blur" },
        {
          min: 6,
          max: 15,
          message: "用户密码长度为6-15个字符",
          trigger: "blur",
        },
      ],
      newPassword: [
        { required: true, message: "请输入用户新密码", trigger: "blur" },
        {
          min: 6,
          max: 15,
          message: "用户密码长度为6-15个字符",
          trigger: "blur",
        },
      ],
      password: [
        { required: true, message: "请输入用户密码", trigger: "blur" },
        {
          min: 6,
          max: 15,
          message: "用户密码长度为6-15个字符",
          trigger: "blur",
        },
      ],
      confirmPassword: [
        { required: true, validator: validatePassword, trigger: "blur" },
      ],
      confirmPasswordOther: [
        { required: true, validator: validatePasswordOther, trigger: "blur" },
      ],
    });
    // 极验初始化
    const initGeetest = (callback) => {
      common.initGeetest().then((response) => {
        window.initGeetest(
          {
            gt: response.data.gt,
            challenge: response.data.challenge,
            offline: !response.data.success,
            new_captcha: true,
            product: "bind",
          },
          function (captchaObj) {
            captchaObj
              .onReady(function () {
                // 必须ready之后才能调用captchaObj.verify()方法
                captchaObj.verify();
              })
              .onSuccess(function () {
                let result = captchaObj.getValidate();
                forgrtForm.challenge = result.geetest_challenge;
                forgrtForm.validate = result.geetest_validate;
                forgrtForm.seccode = result.geetest_seccode;
                callback();
              })
              .onError(function () {
                console.log("geetest error");
              });
          }
        );
      });
    };
    let forgetPasswordDialogVisible = ref(false);
    const forgetPassword = () => {
      forgetPasswordDialogVisible.value = true;
    };
    let forgrtForm = reactive({
      phone: null,
      oldPassword: null,
      newPassword: null,
      challenge: null,
      validate: null,
      seccode: null,
      code: null,
      password: null,
    });
    // 获取验证码 60s
    let sendAuthCode = ref(true);
    let auth_time = ref(0);
    const getAuthCode = () => {
      ForgrtForm.value.validateField("phone", (phoneError) => {
        if (!phoneError) {
          initGeetest(() => {
            sendAuthCode.value = false;
            common
              .sendSms({
                phone: forgrtForm.phone,
                challenge: forgrtForm.challenge,
                validate: forgrtForm.validate,
                seccode: forgrtForm.seccode,
              })
              .then((response) => {
                ElMessage({
                  message: "短信已发送",
                  type: "success",
                });
                // clearInterval(auth_timetimer);
                auth_time.value = 60;
                var auth_timetimer = setInterval(() => {
                  auth_time.value--;
                  if (auth_time.value <= 0) {
                    sendAuthCode.value = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              });
          });
        } else {
          return false;
        }
      });
    };
    let ForgrtForm = ref();
    let forgrtType = ref(false);
    const changeNow = () => {
      ForgrtForm.value.validate((valid) => {
        if (valid) {
          if (forgrtType.value == false) {
            //密码修改
            webUser
              .updatePassword({
                oldPassword: forgrtForm.oldPassword,
                newPassword: forgrtForm.newPassword,
              })
              .then((res) => {
                forgetPasswordDialogVisible.value = false;
                ElNotification({
                  title: "成功",
                  message: "密码修改成功,请重新登陆！",
                  type: "success",
                });
                outlogin();
              });
          } else {
            //验证码修改
            auth
              .forgetPassword({
                phone: forgrtForm.phone,
                password: forgrtForm.password,
                code: forgrtForm.code,
                type: "web",
              })
              .then((response) => {
                forgetPasswordDialogVisible.value = false;
                ElNotification({
                  title: "成功",
                  message: "密码修改成功,请重新登陆！",
                  type: "success",
                });
                outlogin();
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    };

    let upform = ref();

    function goAI() {
      window.open("https://duofangai.com/");
      // window.open().location.href ='https://duofangai.com/'
    }

    function submitForm() {
      upform.value.validate((valid) => {
        if (valid) {
          if (store.getters.userType == "system") {
            user
              .updatePassword({
                oldPassword: upForm.oldPassword,
                newPassword: upForm.newPassword,
              })
              .then((res) => {
                ElNotification({
                  title: "成功",
                  message: "密码修改成功,请重新登陆！",
                  type: "success",
                });
                resetForm();
                outlogin();
              });
          }
          if (store.getters.userType == "web") {
            webUser
              .updatePassword({
                oldPassword: upForm.oldPassword,
                newPassword: upForm.newPassword,
              })
              .then((res) => {
                ElNotification({
                  title: "成功",
                  message: "密码修改成功,请重新登陆！",
                  type: "success",
                });
                resetForm();
                outlogin();
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }

    function resetForm() {
      centerDialogVisible.value = false;
      upform.value.resetFields();
    }

    function outlogin() {
      systemAuth.logout().then((res) => {
        if (store.getters.userType == "system") {
          store.dispatch("logout");
          router.push("/manager/login");
        }
        if (store.getters.userType == "web") {
          store.dispatch("logout");
          router.push("/login");
        }
      });
    }

    // =================================== end ===========================
    // ================================= 消息模块 ================================
    // 全部已读
    function setSystemAll(type) {
      message
        .allRead({
          type: type,
        })
        .then((res) => {
          ElNotification({
            title: "消息全部已读",
            // message: '密码修改成功,请重新登陆！',
            type: "success",
          });
          openDrawer();
          getMessagsUnread();
        });
    }

    function setWebAll(type) {
      webMessage
        .allRead({
          type: type,
        })
        .then((res) => {
          ElNotification({
            title: "消息全部已读",
            // message: '密码修改成功,请重新登陆！',
            type: "success",
          });
          openDrawer();
          getMessagsUnread();
        });
    }

    //全部已读
    function allRead() {
      if (store.getters.userType == "system") {
        if (activeKey.value == "1") {
          setSystemAll(0);
        } else {
          setSystemAll(1);
        }
      }
      if (store.getters.userType == "web") {
        if (activeKey.value == "1") {
          setWebAll(0);
        } else {
          setWebAll(1);
        }
      }
    }

    // 点击已读
    function setMessageRead(id) {
      if (store.getters.userType == "system") {
        message
          .messageRead({
            id: id,
          })
          .then((res) => {
            ElNotification({
              title: "消息已读",
              // message: '密码修改成功,请重新登陆！',
              type: "success",
            });
            getMessagsUnread();
            openDrawer();
          });
      }
      if (store.getters.userType == "web") {
        webMessage
          .messageRead({
            id: id,
          })
          .then((res) => {
            ElNotification({
              title: "消息已读",
              // message: '密码修改成功,请重新登陆！',
              type: "success",
            });
            getMessagsUnread();
            openDrawer();
          });
      }
    }

    // 打开消息抽屉
    let newMessage = ref(false);
    let drawer = ref(false);
    const openDrawer = () => {
      drawer.value = true;
      if (store.getters.userType == "system") {
        if (activeKey.value == "1") {
          getMessagsList(0, null);
        } else {
          getMessagsList(1, null);
        }
      }
      if (store.getters.userType == "web") {
        if (activeKey.value == "1") {
          getWebMessagsList(0, null);
        } else {
          getWebMessagsList(1, null);
        }
      }
    };
    let messagsList = ref([]);
    const pagefrom = reactive({
      page: 1,
      pageSize: 10,
    });

    function getMessagsList(type, isRead) {
      message
        .messageList({
          isRead: isRead,
          type: type,
          page: pagefrom.page,
          pageSize: pagefrom.pageSize,
        })
        .then((res) => {
          messagsList.value = res.data.records;
        });
    }

    function getWebMessagsList(type, isRead) {
      webMessage
        .messageList({
          isRead: isRead,
          type: type,
          page: pagefrom.page,
          pageSize: pagefrom.pageSize,
        })
        .then((res) => {
          messagsList.value = res.data.records;
        });
    }

    function getMessagsUnread() {
      if (store.getters.userType == "system") {
        message.messageUnRead({}).then((res) => {
          if (res.data.unRead != 0) {
            newMessage.value = true;
          } else {
            newMessage.value = false;
          }
        });
      }
      if (store.getters.userType == "web") {
        webMessage.messageUnRead({}).then((res) => {
          if (res.data.unRead != 0) {
            newMessage.value = true;
          } else {
            newMessage.value = false;
          }
        });
      }
    }

    // 无限加载
    function load() {
      pagefrom.pageSize += 10;
      openDrawer();
    }

    let activeKey = ref("1");
    watch(
      () => activeKey.value,
      (newVal, oldVal) => {
        messagsList.value = [];
        if (store.getters.userType == "system") {
          if (activeKey.value == "1") {
            getMessagsList(0, null);
          } else {
            getMessagsList(1, null);
          }
        }
        if (store.getters.userType == "web") {
          //getWebMessagsList
          if (activeKey.value == "1") {
            getWebMessagsList(0, null);
          } else {
            getWebMessagsList(1, null);
          }
        }
      }
    );
    // ========================== 消息模块 end =====================
    // ************************* 搜索栏 ************************* //
    let restaurants = ref([]);
    let search = ref("");
    const querySearch = (queryString, cb) => {
      let _restaurants = restaurants.value;
      let results = queryString
        ? _restaurants.filter(createFilter(queryString))
        : _restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    };
    const createFilter = (queryString) => {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    };
    const loadAll = () => {
      return [
        { value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" },
        {
          value: "Hot honey 首尔炸鸡（仙霞路）",
          address: "上海市长宁区淞虹路661号",
        },
        {
          value: "新旺角茶餐厅",
          address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
        },
        { value: "泷千家(天山西路店)", address: "天山西路438号" },
        {
          value: "胖仙女纸杯蛋糕（上海凌空店）",
          address: "上海市长宁区金钟路968号1幢18号楼一层商铺18-101",
        },
        { value: "贡茶", address: "上海市长宁区金钟路633号" },
        {
          value: "豪大大香鸡排超级奶爸",
          address: "上海市嘉定区曹安公路曹安路1685号",
        },
        {
          value: "茶芝兰（奶茶，手抓饼）",
          address: "上海市普陀区同普路1435号",
        },
        { value: "十二泷町", address: "上海市北翟路1444弄81号B幢-107" },
      ];
    };
    const handleSelect = (item) => {
      console.log(item);
    };
    // ************************* 搜索栏 end ************************* //

    // ************************* 导航全屏处理 ************************* //
    let fullScreen = ref(false);

    const handleFullScreen = () => {
      let element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen();
      }
      // this.index++;
    };

    const exitFullscreen = () => {
      let doc = document;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.webkitCancelFullScreen) {
        doc.webkitCancelFullScreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }
      // this.index--;
    };

    // 全屏按钮点击事件
    const fullScreenHandler = () => {
      if (fullScreen.value == false) {
        fullScreen.value = true;
        handleFullScreen();
      } else {
        fullScreen.value = false;
        exitFullscreen();
      }
    };
    // ************************* 导航全屏处理 end ************************* //

    watch(
      () => route.matched,
      (newVal, oldVal) => {
        let matched = newVal;
        routes.value = matched;
        breadcrumbs.value = getParents(
          allMenus.value,
          (x) => x.id == route.meta.id
        )?.reverse();
      }
    );

    // ==========================设置密码=======================================
    let setPasswordDialogVisible = ref(false);
    let setPasswordfrom = ref();
    let setPasswordFrom = reactive({
      password: "",
    });

    // 新用户初始化密码
    function submitPasswordForm() {
      setPasswordfrom.value.validate((valid) => {
        if (valid) {
          webUser
            .initPassword({
              newPassword: setPasswordFrom.password,
            })
            .then((res) => {
              ElNotification({
                title: "成功",
                message: "密码设置成功！",
                type: "success",
              });
              setPasswordDialogVisible.value = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }

    function resetPasswordForm() {
      setPasswordDialogVisible.value = false;
      setPasswordfrom.value.resetFields();
    }

    onMounted(() => {
      perfectForm.phone = store.getters.user.phone;
      emitter.on("new", (target) => {
        if (target == "ok") {
          getMessagsUnread();
        }
      });
      if (route.path == "/Dashboard" || route.path == "/dashboard") {
        showcontent.value = 0;
           router.push({
            path: '/settings/smsSettingsNew',
        });
      } else {
        showcontent.value = 1;
      }
      // if (store.getters.user.options != null) {
      //   if (JSON.parse(store.getters.user.options).alertSetPwd == 1) {
      //     setPasswordDialogVisible.value = true;
      //   }
      // }
      // if (store.getters.userType == "system") {
      //   websocket("/system/ws/", store.getters.user.uid, getToken()); //启动websocket
      // } else {
      //   websocket("/web/ws/", store.getters.user.uid, getToken()); //启动websocket
      // }
         let menus1 = [
        {
          appId: 0,
          children: [
              {
                  appId: null,
                  children: [],
                  createTime: "",
                  icon: "icon-cishu_data-sheet-03",
                  id: "140",
                  name: "状态查询(新)",
                  parentId: "99",
                  path: ",-1,99,",
                  remark: "",
                  route: "/settings/smsSettingsNew",
                  sort: 3,
                  status: 0,
                  type: 0,
                  updateTime: "",
                  url: "/settings/smsSettingsNew",
                },
                 {
                  appId: 0,
                  children: [],
                  createTime: "",
                  icon: "icon-huadanchaxun",
                  id: "141",
                  name: "短信验证(新)",
                  parentId: "99",
                  path: ",-1,99,",
                  remark: "",
                  route: "/settings/noteSettingsNew",
                  sort: 3,
                  status: 0,
                  type: 0,
                  updateTime: "",
                  url: "/settings/noteSettingsNew",
                },
                {
                  appId: 0,
                  children: [],
                  createTime: "",
                  icon: "icon-huadanchaxun",
                  id: "142",
                  name: "能力号激活",
                  parentId: "99",
                  path: ",-1,99,",
                  remark: "",
                  route: "/settings/textaPendulum",
                  sort: 3,
                  status: 0,
                  type: 0,
                  updateTime: "",
                  url: "/settings/textaPendulum",
                },
                {
                  appId: 0,
                  children: [],
                  createTime: "",
                  icon: "icon-huadanchaxun",
                  id: "143",
                  name: "能力号IP",
                  parentId: "99",
                  path: ",-1,99,",
                  remark: "",
                  route: "/settings/ctccIp",
                  sort: 3,
                  status: 0,
                  type: 0,
                  updateTime: "",
                  url: "/settings/ctccIp",
                },
            // {
            //   appId: 0,
            //   children: [
            //     {
            //       appId: null,
            //       children: [],
            //       createTime: "",
            //       icon: "icon-cishu_data-sheet-03",
            //       id: "140",
            //       name: "状态查询(新)",
            //       parentId: "99",
            //       path: ",-1,99,",
            //       remark: "",
            //       route: "/settings/smsSettingsNew",
            //       sort: 3,
            //       status: 0,
            //       type: 0,
            //       updateTime: "",
            //       url: "/settings/smsSettingsNew",
            //     },
            //     {
            //       appId: 0,
            //       children: [],
            //       createTime: "",
            //       icon: "icon-huadanchaxun",
            //       id: "141",
            //       name: "短信验证(新)",
            //       parentId: "99",
            //       path: ",-1,99,",
            //       remark: "",
            //       route: "/settings/noteSettingsNew",
            //       sort: 3,
            //       status: 0,
            //       type: 0,
            //       updateTime: "",
            //       url: "/settings/noteSettingsNew",
            //     },
            //   ],
            //   createTime: "",
            //   icon: "icon-jiekouguanli",
            //   id: "99",
            //   name: "常用工具",
            //   parentId: "-1",
            //   path: ",-1,",
            //   remark: "",
            //   route: "/default",
            //   sort: 1,
            //   status: 0,
            //   type: 0,
            //   updateTime: null,
            //   url: "",
            // },
          ],
          createTime: "",
          icon: "icon-copy-4-07",
          id: "-1",
          name: "主页",
          parentId: "0",
          path: ",",
          remark: "顶级菜单",
          route: "/",
          sort: 0,
          status: 0,
          statusText: "显示",
          type: 0,
          typeText: "link",
          updateTime: null,
          url: "/",
        },
      ];
      let show = {};
      show.menus = menus1;
      allMenus.value = [show.menus[0]].filter((x) => x.status == 0);
      menus.value = show.menus[0].children.filter((x) => x.status == 0);
      breadcrumbs.value = getParents(
        allMenus.value,
        (x) => x.id == route.meta.id
      )?.reverse();
      getMessagsUnread();
      routes.value = route.matched;
      // allMenus.value=[]
      console.log(
        store.getters.menus,
        "55555555555555555555555555555555555555"
      );
      restaurants.value = loadAll();
    });

    return {
      userType,
      zhCN,
      collapsed: ref(false),
      routes,
      openDrawer,
      drawer,
      activeKey,
      search,
      restaurants,
      querySearch,
      handleSelect,
      store,
      menus,
      newMessage,
      route,
      breadcrumbs, // 面包屑集合
      fullScreenHandler, // 全屏方法
      fullScreen, // 全屏标识
      messagsList,
      show: ref(false),
      centerDialogVisible,
      centerDialogVisible1,
      upForm,
      rules,
      upform,
      submitForm,
      resetForm,
      outlogin,
      getMessagsUnread,
      setMessageRead,
      load,
      pagefrom,
      allRead,
      goPersonal,
      jumpUrl,
      setPasswordDialogVisible,
      setPasswordFrom,
      setPasswordfrom,
      resetPasswordForm,
      submitPasswordForm,
      openDialogVisible,
      openAppId,
      closeDiv,
      showcontent,
      showcontentChange,
      perfectForm,
      perfectForms,
      saveInformation,
      forgetPasswordDialogVisible,
      forgetPassword,
      forgrtForm,
      forgrtType,
      sendAuthCode,
      auth_time,
      getAuthCode,
      forgrtRules,
      ForgrtForm,
      changeNow,
      goAI,
      balance,
      getData,
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  cursor: pointer;

  height: 30px;
  padding: 0 6px 0 6px;
  border-radius: 6px;
  background-color: #edf0ff;
  color: #1a42ff;
  font-size: 14px;
}

.szyioc {
  margin-right: 5px;
  width: 15px;
  height: 15px;
}

.right-nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  .img-icon {
    cursor: pointer;
    color: #7594ff;
    font-size: 1.5rem;
  }

  .img-icon-other {
    cursor: pointer;
    color: #0b1441;
    font-size: 1rem;
  }

  .user-box {
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    .user-img {
      border-radius: 50%;
      border: solid 0.125rem #707070;
      width: 2.4125rem;
      height: 2.4125rem;
    }

    .user-data {
      margin-left: 0.7rem;

      .name {
        color: #24252f;
        font-size: 0.815rem;
        font-weight: 600;
      }

      .role {
        color: #7594ff;
        font-size: 0.615rem;
        font-weight: 500;
      }
    }
  }
}

.outlogin-color {
  color: #f56c6c;
}

.out-box-s {
  position: fixed;
  right: -0.3125rem;
  top: 5.125rem;
  z-index: 1;
}

.transition-box {
  margin-bottom: 0.625rem;
  width: 8.125rem;
  height: 6.25rem;
  border-radius: 0.25rem;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 2.5rem 1.25rem;
  box-sizing: border-box;
  margin-right: 1.25rem;
}

// 消息抽屉
.the-drawer {
  // background: #E8ECFF;
  // height: 4.375rem;
  width: 100%;
  // display: flex;
  // flex-wrap: nowrap;
  // justify-content: space-between;
  // align-items: center;
}

.trigger {
  font-size: 1.25rem;
  padding: 0.125rem 0.9375rem;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 4rem;
}

.logo img {
  height: 1.875rem;
  max-height: 100%;
  max-width: 100%;
}

.site-layout .site-layout-background {
  background: #fff;
}

.home {
  .menus {
    height: calc(100vh - 70px);
    overflow-y: overlay;
    overflow-x: hidden;
  }

  .menus::-webkit-scrollbar {
    width: 0;
  }

  .right {
    border-radius: 1em 0 0 0;
    background-image: linear-gradient(0deg, #eceef8, #eceef8 88%, #aebaf8);

    .header {
      // background: #fff;
      padding: 0 0.9375rem 0 0;
      height: 3.25rem;
      margin: 0.9375rem 0.9375rem 0 0.9375rem;
      // border-radius: 1em;
      // box-shadow: 0px 0px 6px 0px #d3d6e2;
    }

    .content {
      height: calc(100vh - 100px);
      margin: 0.9375rem;
      padding: 0.9375rem 0 0.9375rem 0.9375rem;
      border-radius: 1em;
      background: #fff;
      box-shadow: 0px 0px 6px 0px #d3d6e2;
    }

    .contentOther {
      height: calc(100vh - 100px);
    }
  }
}

.right-layout {
  background: #ffffff;
}

.message-list {
  height: calc(100vh - 160px);
  overflow-y: overlay;
}

.message-box {
  padding: 1.25rem;

  .message-time {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .message-text {
    padding: 1.25rem;
    background: #f2f6fc;
    border-radius: 0.666rem;
    cursor: pointer;

    .message-title {
      font-size: 0.9375rem;
      font-weight: 600;
      margin-bottom: 0.666rem;
    }
  }
}

.icon-unread-color {
  cursor: pointer;
  // color: #F56C6C !important;
  font-size: 1rem;
}

.icon-read-color {
  cursor: pointer;
  color: #67c23a;
  font-size: 1rem;
}

.operation-top {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.icon-operation {
  cursor: pointer;
  color: #7594ff;
  font-size: 1.5rem;
}

.operation-top-item {
  padding: 1rem;
}

.icon-first-font {
  font-size: 22px;
}

.icon-font-out-box {
  width: 45px;
  height: 45px;
  background: #e9edff;
  border-radius: 50%;
}

.icon-font-out-title {
  font-size: 14px;
  font-weight: 700;
  color: #637dff;
}

.perfectForm-box {
  padding: 0 30px;
}
</style>

<style lang="scss">
.right-nav .search-input .inline-input {
  .el-input__inner {
    border-radius: 1.25rem !important;
    border-color: #d8ddf8;
    height: 2.125rem;
    background-color: #f3f5ff;
  }

  .el-input__inner:focus {
    outline: 0;
    border-color: #7a90ff;
  }
}

.right-nav .ant-badge {
  margin-top: 0.25rem !important;
}

// .home .ant-layout {
//   background: #FFFFFF !important;
// }

.ant-layout-sider {
  transition: none !important;
}

.ant-menu-title-content {
  font-weight: 600;
}

.flex-flex-end {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.allRead-box {
  cursor: pointer;
}

.bgcolor {
  color: #7596ff;
}

.yinying {
  display: inline-block;
  padding: 0.5rem;
  background: #f8f8f8;
  border-radius: 0.25rem;
}

.img-icon1 {
  cursor: pointer;
  color: #637dff;
  font-size: 1rem;
}

.forget-password {
  color: #637dff;
  cursor: pointer;
}
</style>
